export const RETURNS = {
  CLIENT_RETURN_TRACING_SITE: 'client_return_tracing_site',
} as const;

export const ADVERT_AGREEMENT = {
  LK_MAIL: 'lk_mail',
  LK_MAIL_AD_CONFIRM: 'lk_mail_ad_confirm',
  LK_AD: 'lk_ad',
  LK_AD_CONFIRM: 'lk_ad_confirm',
} as const;

export const GIFT = {
  GIFTS_CLICK: 'gifts_click',
} as const;

export const enum CdekIdGoal {
  TINKOFF = 'cdekid_tinkoff',
  ALFA_SITE = 'cdekid_alfa_site',
  MOBILEID_SITE = 'cdekid_mobileid_site',
  FORM_SITE = 'cdekid_form_site',
}

export const BANNERS_YM_PARAMS = {
  MAIN_BANNER_DISPLAY: 'main_banner_display',
  MAIN_BANNER_CLICK: 'main_banner_click',
} as const;

export const ORDER_WIDGET_AB_PARAM = 'new_calc_widget_AB';

export const CALC_WIDGET_INTERACT = 'calc_widget_interact';

export const AUTH_AB = {
  VISIT_PARAM: 'auth_test',
  AUTH_ATTEMPT: 'auth_attempt',
  AUTH_SUCCESS: 'auth_success',
  AUTH_REDIRECT: 'auth_redirect',
};

export const B2B_CALC_METRICS = {
  BUTTON_ORDER_CLICK: 'button_order_click',
  BUTTON_ORDER_MARKET_CLICK: 'button_order_market_click',
  BUTTON_CALCULATE_CLICK: 'button_calculate_click',
  MARKET_SUGGEST: 'market_suggest',
  MARKET_ALERT: 'market_alert',
  MARKET_ALERT_CITY_PARAM: 'market_alert_city',
} as const;

export const PAYMENT_METRICS = {
  PAYMENT_OLD_REDIRECT: 'payment_old_redirect',
  PAYMENT_NEW_REDIRECT: 'payment_new_redirect',
};

export const B2B_B2C = {
  CONTRACT_SEND_SUCCESS: 'contract_send_success',
};

export const MOBILE_APP_BANNER_YM_GOALS = {
  MOBILE_BANNER_DISPLAY: 'mobile_banner_display',
  MOBILE_BANNER_CLICK: 'mobile_banner_click',
};
